<template>
    <div class='autocomplete'>
      <fieldset>
      <slot name='legend'></slot>
      <div class='field'>
        <div class='control'>
          <input type='text' placeholder='Start typing to search..' class='input' v-on:keyup='onKeyup' v-model='search'>
        </div>
      </div>
      </fieldset>
      <ul>
        <li v-for='candidate in filteredCandidates'><a :href='candidateUrl(candidate)'>{{ candidate.name }}</a></li>
      </ul>
    </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  const _ = require('lodash');
  export default {
  data: function(){
    return {
      search: '',
      candidates: [],
      filteredCandidates: [],
      hasNoCandidates: false
    }
  },
  props: {
    'url': {
      type: String,
      default: '/candidates/'
    },
    'admin':{
      type: Boolean,
      default: false
    }
  },
  methods: {
    onKeyup: _.debounce( function(){
      this.filteredCandidates = [];
      this.candidates.forEach( candidate => {
          if(candidate.name.toLowerCase().match(new RegExp(this.search.toLowerCase()))){
            this.filteredCandidates.push(candidate);
          }
        }
      );

    }, 500),
    candidateUrl (candidate) {
      let baseUrl = this.url + candidate.uid;
      if (this.admin === true) {
        baseUrl = this.url + candidate.id + '/edit'
      }
      return baseUrl;
    }
  },
  created: function(){
    this.candidates = candidates;
    this.filteredCandidates = candidates;
    this.loadCandidates = function(response){
      this.hasNoCandidates = response.data.results.length > 0 ? false : true;
      this.candidates = response.data.results;
      this.$emit('onresults', response.data)
    }.bind(this)
  }
}
</script>

<style scoped>
  ul {
    list-style-type: none;
  }
  @media (min-width:920px){
    ul {
      -moz-column-count: 3;
      -moz-column-gap: 20px;
      -webkit-column-count: 3;
      -webkit-column-gap: 20px;
      column-count: 3;
      column-gap: 20px;
    }
  }
</style>