<template>
    <div class='autocomplete'>
      <fieldset>
      <slot name='legend'></slot>
      <div class='field'>
        <div class='control'>
          <input type='text' placeholder='Search for a local authority' class='input' v-on:keyup='onKeyup' v-model='search'>
        </div>
      </div>
      </fieldset>
      <ul>
        <li v-for='candidate in filteredCandidates'><a href='' @click.prevent='onselect(candidate.uid)'>{{ candidate.name }}</a></li>
      </ul>
    </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  const _ = require('lodash');
  export default {
  data: function(){
    return {
      search: '',
      candidates: [],
      filteredCandidates: [],
      hasNoCandidates: false,
      selectedCandidates: [],
      countryScope: 'england'
    }
  },
  props: ['url'],
  methods: {
    onKeyup: _.debounce( function(){
      this.filteredCandidates = [];
      this.candidates.forEach( candidate => {
          if(this.search !== "" && this.isCandidateMatch(candidate)) {
            this.filteredCandidates.push(candidate);
          }
        }
      );

    }, 500),

    isCandidateMatch: function(candidate){
      let searchTerms = this.search.toLowerCase();
      let nameMatch = candidate.name.toLowerCase().match(new RegExp(searchTerms))
      let countryMatch = candidate.country == this.countryScope;
      return nameMatch && countryMatch;
    },

    onselect: function(uid){
      this.selectedCandidates.push(uid);
      let url = '/candidates/compare/' + selectedCandidates.join('/')
      location.href = url
    }

  },
  created: function(){
    this.candidates = candidates;
    this.selectedCandidates = selectedCandidates;
    this.countryScope = countryScope;
    this.filteredCandidates = [];
    this.loadCandidates = function(response){
      this.hasNoCandidates = response.data.results.length > 0 ? false : true;
      this.candidates = response.data.results;
      this.$emit('onresults', response.data)
    }.bind(this)
  }
}
</script>

<style scoped>

  fieldset {
    margin-bottom: 0;
  }

  fieldset input {
    margin-bottom: 0;
    border-radius: 3px 3px 0 0;
  }

  ul {
    list-style-type: none;
    max-height: 100px;
    overflow-y: scroll;
    border: 1px solid #dedede;
    border-top: none;
  }
  ul li {
     padding: 0 5px;
     margin: 5px 0;
  }

</style>