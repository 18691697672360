import Vue from 'vue';
import Finder from 'js/components/finder.vue';
import ComparisonTool from 'js/components/comparison-tool.vue';
import Explorer from 'js/components/explorer.vue';
import feather from 'feather-icons';
import Trix from 'trix' 

(function(){

  document.addEventListener("trix-initialize", function(event) {
      const { toolbarElement } = event.target
      const inputElement = toolbarElement.querySelector("input[name=href]")
      inputElement.type = "text"
      inputElement.pattern = "(https?://|/).+"
    })
  document.addEventListener('DOMContentLoaded', onLoad);

  const $ = selector => document.querySelectorAll(selector);

  let nav, toggle;

  function Expander(el){
    this.open = false;
    this.tgt = document.getElementById(el.dataset.expand)
    this.el = el;
    if (el.dataset.open == 'true') {
      this.open = true;
      this.el.classList.add('expandable-open')
      this.tgt.classList.add('expandable-content', 'expandable-open')
    } else {
      this.el.classList.add('expandable-closed')
      this.tgt.classList.add('expandable-content', 'expandable-closed')
    }
    this.el.addEventListener('click', this.toggle.bind(this))
  }

  Expander.prototype.toggle = function(ev){
    ev.preventDefault();
    this.open = !this.open;
    if(this.open){
      this.el.classList.add('expandable-open')
      this.tgt.classList.add('expandable-open')
      this.tgt.classList.remove('expandable-closed')
      this.el.classList.remove('expandable-closed')
    } else {
      this.tgt.classList.remove('expandable-open')
      this.tgt.classList.add('expandable-closed')
      this.el.classList.remove('expandable-open')
      this.el.classList.add('expandable-closed')
    }
  }

  function onLoad(){

    //Trix.config.blockAttributes.heading1.tagName = 'h2';


    let token = document.querySelectorAll('meta[name="csrf-token"]')[0].content
    Vue.prototype.$csrfToken = token;
    feather.replace();

    let finder = $('#finder')[0];
    if(finder){
      initFinder();
    }

    let explorer = $('#explorer')[0];
    if(explorer){
      initExplorer();
    }

    let compTool = $('#comparison-tool')[0];
    if(compTool){
      initCompTool();
    }

    nav = $('#main-nav')[0];
    toggle = $('#nav-toggle')[0];
    toggle.addEventListener('click', toggleNav);

    initDropdowns();

    let candidateClosers = $('.candidate-close');
    candidateClosers.forEach(function(closer){
      closer.addEventListener('click', removeCandidate);
    })

    initExpanders();

    initTrackedLinks();

  }

  function initTrackedLinks(){
    var trackables = document.querySelectorAll('.tracked');
    trackables.forEach(function(el){
      el.addEventListener('click', function(event){
        event.preventDefault();
        gtag('event', 'click', {
          event_category: 'Document',
          event_action: 'View',
          event_label: el.dataset.track,
          transport_type: 'beacon',
          event_callback: function(){
            document.location = el.href;
          }
        });
      })
    })
  }

  function initDropdowns(){
    $('a.dropdown-toggle').forEach(function(el){
      el.addEventListener('click', toggleOpen)
    })
    document.addEventListener('click', function(ev){
      if ( !ev.target.parentNode.classList.contains('dropdown') &&
          !ev.target.parentNode.classList.contains('dropdown-toggle') &&
          !ev.target.classList.contains('dropdown-toggle')
         ) {
        closeAllDropdowns()
      } else {
        ev.stopPropagation();
      }
    })
  }

  function closeAllDropdowns(except){
    var dropdowns = $('ul.dropdown')
    for(var i = 0; i < dropdowns.length; i++){
      var dropdown = dropdowns[i]
      if(typeof except !== 'undefined'){
        if(except === dropdown){
          continue
        } else {
          dropdown.classList.remove('open')
        }
      } else {
        dropdown.classList.remove('open')
      }
    }
  }

  function toggleOpen(e){
    console.log(e)
    var tgt = e.currentTarget;
    var parent = tgt.parentNode;
    var dropdown = parent.querySelector('.dropdown')
    closeAllDropdowns(dropdown)
    if(dropdown.classList.contains('open')){
      dropdown.classList.remove('open')
    } else {
      dropdown.classList.add('open')
    }
  }

  function removeCandidate(e){
    e.preventDefault();
    let tgt = e.currentTarget.getAttribute('data-candidate');
    if(tgt){
      selectedCandidates.splice(selectedCandidates.indexOf(tgt), 1);
      location.href = '/candidates/compare/' + selectedCandidates.join('/')
    }
  }

  function toggleNav(){

    if(nav.getAttribute('data-open') == 'true'){

      nav.setAttribute('data-open', 'false');
    } else {

      nav.setAttribute('data-open', 'true');
    }
  }

  function initExpanders(){
    $('.expandable').forEach( el => {
      new Expander(el)
    })
  }

  function initExplorer(){
    const explorer = new Vue(
      {render: h => h(Explorer)}
    ).$mount("#explorer");
  }

  function initFinder(){
    let el = document.getElementById('finder');
    const finderApp = new Vue({
      el,
      render: h => h(Finder,
        {props:
          {
            url: el.dataset.url,
            admin: el.dataset.admin
          }
        }
      )
    });
  }

  function initCompTool(){
    const comparisonToolApp = new Vue({
      render: h => h(ComparisonTool)
    }).$mount('#comparison-tool');
  }

}());