<template>
  <div>
    <h2>Results explorer</h2>
    <div class="row">
      <div class="column column-75">
        <div class='domains'>
          <select v-on:change='onSelectDomain($event)'>
            <option :value="null">Choose domain</option>
            <option v-for='domain in domains'>{{domain}}</option>
          </select>
          <div class="buttons">
            <a @click='onSelectDomain(domain)' v-for='domain in domains' :class='["button domain-button", domain == selectedDomain ? "": "button-outline"]'>{{ domain }}</a>
          </div>
        </div>
      </div>
      <div class="column column-25">
        <div id='legend'>
          <img src='../../images/legend.svg' alt='Legend'>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div style='transition:0.5s all' v-bind:class="['column', selectedRegion ? 'column-75' : '']">
        <result-map ref='map' v-on:select='onSelectRegion' v-on:deselect='onDeselectRegion'></result-map>
      </div>
     
        <div v-show='selectedRegion' class="column-25 column">
          <div v-if='selectedRegion'>
          <h3>{{ selectedRegion.name }}<small><a href='' @click.prevent='onDeselectRegion'>X</a></small></h3>
          <div v-html='summaryImage'></div>
          <a v-bind:href="'/candidates/' + this.selectedRegion.id" class='button'>View full result</a>
          </div>
        </div>
     
    </div>
  </div>
</template>

<script>
  import Map from './map.vue'
  import axios from 'axios';
  export default {
    components: {
      'result-map': Map
    },
    data(){
      return {
        selectedDomain: null,
        selectedRegion: null,
        summaryImage: "",
        domains: ['Local Conditions', 'Equality', 'Sustainability', 'Work and local economy', 'Mental and physical health', 'Education and learning', 'Place and environment', 'People and community']
      }
    },
    methods: {
      onSelectRegion(selection){
        this.selectedRegion = selection;
        //console.log(this.selectedRegion)
        axios.get('candidates/' + this.selectedRegion.id + '/summary.svg').then(function(resp){
          this.summaryImage = resp.data;
        }.bind(this));
      },
      onDeselectRegion(){
        this.selectedRegion = null;
        this.$refs.map.deselectRegion();
      },
      onSelectDomain(domain){
        if(typeof domain.target !== 'undefined'){
          domain = domain.target.value;
        }
        if(domain == this.selectedDomain){
          this.selectedDomain = null;
          this.$refs.map.deselectRegion();
          return;
        }
        this.selectedDomain = domain;
        axios.post('/results/domain.json', {domain: domain}, { headers: {'X-CSRF-Token': this.$csrfToken} }).then(function(resp){
          this.$refs.map.updateScores(resp.data);
        }.bind(this));
      }
    }
  }
</script>

<style scoped>

  #legend {
    max-width: 320px;
  }

  .domain-button {
    margin-right: 5px;
    padding: 0 7px;
  }
  h3 small {
    float: right;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .buttons {
    display: none;
  }

  @media (min-width:920px){
    .buttons {
      display: block;
    }
    .domains select {
      display: none;
    }
  }
</style>