<template>
  <div id='map'></div> 
</template>

<script>
  import L from 'leaflet';
  const pathToMapData = require.context('../../map', true, /kml$/);
  export default {
    methods: {
      updateScores(results){
        this.results = results;
        this.boundaryLayer.eachLayer((layer) => {
          let isSelected = layer.feature.properties.selected;
          let weightedStyle = Object.assign({}, this.defaultStyle);
          if(isSelected == true){
            weightedStyle.weight = 6;
            weightedStyle.color = "#ffffff";
            layer.bringToFront();
          }

          let uid = layer.feature.properties.ctyua15cd;
          if(this.results.length == 0){
            layer.setStyle(weightedStyle);
          }
          this.results.forEach((result) => {
            if(uid == result.uid){
              weightedStyle.fillColor = result.color;
              weightedStyle.fillOpacity = 0.65;
              layer.setStyle(weightedStyle);
            }
          });
        });   
      }
    },
    data(){
      return {
        selectedId: null,
        results: []
      }
    },
    created(){
      this.defaultStyle = {
        color: "#888",
        weight: 2,
        opacity: 1,
        fillOpacity: 0.0,
        fillColor: "#2262CC"
      };
       this.highlightStyle = {
        color: '#2262CC', 
        weight: 3,
        opacity: 0.6,
        fillOpacity: 0.65,
        fillColor: '#2262CC'
      };
      

      this.deselectAll = function(){
        //this.results = [];
        this.boundaryLayer.eachLayer(
          layer => {
            layer.feature.properties.selected = false;
            let layerStyle = Object.assign({}, this.defaultStyle);
            layer.setStyle(layerStyle);
          }
        );
      };

      this.deselectRegion = function(){
        this.boundaryLayer.eachLayer((layer) => {
          layer.feature.properties.selected = false;
        });
        this.updateScores(this.results);
      }

      this.selectLayer = function(selected){
        this.deselectAll();
        selected.layer.feature.properties.selected = true;
        this.map.setView([selected.latlng.lat, selected.latlng.lng], 8);
        this.updateScores(this.results);
        this.$emit('select', selected);
      }
    },
    mounted(){
      
      // DEFAULT STYLE
      // https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}
      
      // MAPBOX STYLE
      // https://api.mapbox.com/styles/v1/theflyingbrush/cir6e1yi90016chnh8hsk6fqr/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhlZmx5aW5nYnJ1c2giLCJhIjoiY2lrOXhsemQ0MGZuenV5bTRsYzF3OGg1cyJ9.OOTM7rJ6jJ9tReoFexroXg
      this.map = L.map('map').setView([54.505, -4.09], 5);
      L.tileLayer('https://api.mapbox.com/styles/v1/theflyingbrush/cir6e1yi90016chnh8hsk6fqr/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox.streets',
      accessToken: 'pk.eyJ1IjoidGhlZmx5aW5nYnJ1c2giLCJhIjoiY2lrOXhsemQ0MGZuenV5bTRsYzF3OGg1cyJ9.OOTM7rJ6jJ9tReoFexroXg'
      }).addTo(this.map);
      
      const kmlPath = pathToMapData('./candidates.kml', true);
      
      let app = this;
      this.boundaryLayer = omnivore.kml(kmlPath).on('ready', () => {
        app.map.fitBounds(app.boundaryLayer.getBounds());
        this.boundaryLayer.eachLayer((layer) => {
          layer.feature.properties.selected = false;
          layer.on('click', function(selected){
            let selectedInfo = {id: selected.target.feature.properties.ctyua15cd, name: selected.target.feature.properties.ctyua15nm, latlng: selected.latlng, layer: layer}
            app.selectLayer(selectedInfo);
            
          });

          layer.setStyle(app.defaultStyle);

        })
      }).addTo(this.map);
    }
  }
</script>