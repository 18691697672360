/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log('Hello World from Webpacker')
import 'milligram';
import 'scss/main.scss';
import 'vue';
import 'js/main.js';
import 'js/components/finder.vue';
import 'js/components/explorer.vue';
import 'js/components/map.vue';
import 'js/components/comparison-tool.vue';
import 'images/logo.png';
import 'images/image1.jpg';
import 'images/calibrating.jpg';
import 'images/logo.svg';
import 'images/powered-by.png';
import 'images/legend.svg';
import 'images/framework.jpg';
import 'images/framework-full.jpg';
import 'images/tpi-new-framework.png';
import 'images/tpi-full-framework.png';
import 'images/cover.jpg';
import 'images/Building-a-Wellbeing-Economy-Roadmap-for-Towns.jpg';
import 'images/Wellbeing-Economy-Data-Guide-for-Towns.jpg';
import 'images/cover-summary.jpg';
import 'images/report2019Summary.png';
import 'images/report2019full.jpg';
import 'images/cover2.jpg';
import 'images/cover3.jpg';
import 'images/snail-demo.jpg';
import 'images/scorecard-demo.jpg';
import 'images/garden.jpg';
import 'images/people.jpg';
import 'images/cyclist.jpg';
import 'images/meeting.jpg';
import 'images/walk.jpg';
import 'images/equality.png';
import 'images/sustainability.png';
import 'images/snail2020demo.png';
import 'images/localconditions.png';
import 'images/goldenthread.png';
import 'images/demo-scorecard.png';
import 'images/demo-snail.png';
import 'images/2020report.jpg';
import 'images/sc_scores.jpg';
import 'images/press-1.jpg';
import 'images/TPi-framework-2020.jpg';
import 'images/TP2021-keyreport.jpg';
import 'images/TP2022-keyreport.jpg';
import 'images/tpi2020brochure.jpg';
import 'images/triados.png';
import 'images/urban_wellbeing_logo.jpg';
import 'images/urban-wellbeing-stacked.jpg';
import 'images/ctp-logo-1.png';
import 'images/lizpositivenews.jpg';
import 'images/home-people.jpg';
import 'images/maps/east_equality.png';
import 'images/maps/east_localconds.png';
import 'images/maps/east_sustainability.png';
import 'images/maps/eastmidlands_equality.png';
import 'images/maps/eastmidlands_localconds.png';
import 'images/maps/eastmidlands_sustainability.png';
import 'images/maps/headline_equality_upper.png';
import 'images/maps/headline_localconditions_upper.png';
import 'images/maps/headline_sustainability_upper.png';
import 'images/maps/london_equality.png';
import 'images/maps/london_localconds.png';
import 'images/maps/london_sustainability.png';
import 'images/maps/northeast_equality.png';
import 'images/maps/northeast_localconds.png';
import 'images/maps/northeast_sustainability.png';
import 'images/maps/northwest_equality.png';
import 'images/maps/northwest_localconds.png';
import 'images/maps/northwest_sustainability.png';
import 'images/maps/southeast_equality.png';
import 'images/maps/southeast_localconds.png';
import 'images/maps/southeast_sustainability.png';
import 'images/maps/southwest_equality.png';
import 'images/maps/southwest_localconds.png';
import 'images/maps/southwest_sustainability.png';
import 'images/maps/wales_equality.png';
import 'images/maps/wales_localconditions.png';
import 'images/maps/wales_sustainability.png';
import 'images/maps/westmidlands_equality.png';
import 'images/maps/westmidlands_localconds.png';
import 'images/maps/westmidlands_sustainability.png';
import 'images/maps/yorkshirehumber_equality.png';
import 'images/maps/yorkshirehumber_localconds.png';
import 'images/maps/yorkshirehumber_sustainability.png';
import 'images/maps/corecities_equality.png';
import 'images/maps/corecities_localconds.png';
import 'images/maps/corecities_sustainability.png';
import 'lodash';
import 'axios';
import 'feather-icons';
import '../map/candidates.kml';

require("trix")
require("@rails/actiontext")